import React from "react";
import { Helmet } from "react-helmet";
import useSiteMetadata from "../hooks/useSiteMetadata";
import SharePng from "../images/share.png";

const Seo = ({
  title,
  description,
  image,
  ogType = "website",
  twitterCard = "summary",
  structuredData,
}) => {
  const {
    siteUrl,
    title: defaultTitle,
    author,
    description: defaultDescription,
    pathname,
  } = useSiteMetadata();
  const defaultImage = SharePng;

  return (
    <Helmet titleTemplate={`%s | ${defaultTitle}`} defaultTitle={defaultTitle}>
      {title && <title>{title}</title>}
      <meta name="author" content={author} />
      <meta name="description" content={description || defaultDescription} />
      <meta property="og:type" content={ogType} />
      <meta
        property="og:title"
        content={title ? `${title} | ${defaultTitle}` : defaultTitle}
      />
      <meta
        property="og:description"
        content={description || defaultDescription}
      />
      <meta
        property="og:image"
        content={`${siteUrl}${image || defaultImage}`}
      />
      {pathname && <meta property="og:url" content={`${siteUrl}${pathname}`} />}
      <meta property="twitter:card" content={twitterCard} />
      {structuredData && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      )}
    </Helmet>
  );
};

export default Seo;
