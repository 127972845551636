import React from "react";
import { Helmet } from "react-helmet";
import Seo from "./Seo";

const Layout = ({ children, location: { pathname } }) => {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=EB+Garamond:wght@600&family=Manrope:wght@400;500;600;700&display=fallback"
          rel="stylesheet"
        />
      </Helmet>
      <Seo pathname={pathname} />
      {children}
    </>
  );
};

export default Layout;
